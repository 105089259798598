import IAgoraPost from "./IAgoraPost";
import IAgoraExProfile from "./IAgoraExProfile";
import Provider from "./Provider";
import config from './config.json'
import React from "react";
// @ts-ignore
import { TwitterTweetEmbed } from 'react-twitter-embed';

const endpoint = config.api_endpoint

export default class TwitterProvider implements Provider {

    async fetchProfile(uid: string): Promise<IAgoraExProfile> {
        const url = `${endpoint}twitter/user?user=${uid}`

        try {
            let response = await fetch(url)
            let body = await response.json()
            const user = JSON.parse(body.data)

            return {
                username: uid,
                uid: uid,
                source: 'twitter',
                followers: user.followers_count,
                link: `twitter.com/${uid}`
            }
        } catch {
            return null
        }
    }


    async fetchPosts(uid: string): Promise<IAgoraPost[]> {
        const url = `${endpoint}twitter/tweets?user=${uid}`

        try {
            let response = await fetch(url)
            let body = await response.json()
            const posts = JSON.parse(body.data)
            let ps: IAgoraPost[] = []

            ps = posts.map((post: any) => {

                let media = ''
                let text = post.full_text
                let link = ''
                if (post.retweeted_status) {
                    if (post.retweeted_status.entities.media)
                        media =post.retweeted_status.entities.media[0].media_url_https
            
                } else {
                    const link_index =  post.full_text.lastIndexOf('https://')
                    link = post.full_text.substring(link_index)
                    if (post.entities.media)
                        media = post.entities.media[0].media_url_https
                    
                    if (link_index > 0)
                        text = post.full_text.substring(0, link_index)
                }            

                return {
                        source: 'twitter',
                        date: post.created_at,
                        title: text,
                        description: '',
                        link: link,
                        uid: post.id_str,
                        media: media,
                }
            })
            
            return ps

        } catch (err) {
            console.log(err)
            return []
        }
    }

    getEmbeddedPost(post: IAgoraPost): JSX.Element {
        return <TwitterTweetEmbed options={{height: 100}} tweetId={post.uid} />
    }
}