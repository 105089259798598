// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2e1f34f2-e042-4f2f-8d42-c627d4eeba4f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9byTWmdNY",
    "aws_user_pools_web_client_id": "4i70n15u255pklf7et5orf0eat",
    "oauth": {}
};


export default awsmobile;
