import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import IAgoraPost from 'api/external_sources/IAgoraPost'
import IAgoraExProfile from 'api/external_sources/IAgoraExProfile'
import ExternalSourceController from 'api/external_sources/ExternalSourceController'
import { IAgoraData } from 'data/data'

const externalSourceControl = new ExternalSourceController()

interface EntityState {
    label: string,
    description: string,
    profile: IAgoraData
    instance: string,
    profiles: {
        [source: string]: IAgoraExProfile
    }
    loading: boolean,
    hasProfile: boolean,
    posts: IAgoraPost[]
    news: IAgoraPost[]
    relations: {
        id: string,
        article_url: string,
        title: string,
        source: string
    }[]
}

const initialState: EntityState = {
    label: "",
    description: "",
    profile: null,
    instance: 'Group / Organization',
    profiles: {},
    loading: false,
    hasProfile: true,
    posts: [],
    news: [],
    relations: []
}

const entitiyDetails = createSlice({
  name: 'entitiyDetails',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
        state.loading = action.payload
    },
    setProfile(state, action: PayloadAction<IAgoraData>) {
        state.profile = action.payload
    },
    setDescription(state, action: PayloadAction<string>) {
        state.description = action.payload
    },
    setHasProfile(state, action: PayloadAction<boolean>) {
        state.hasProfile = action.payload
    },
    setExProfile(state, action: PayloadAction<IAgoraExProfile>) {
        const profile = action.payload
        state.profiles[profile.source] = profile
    },
    clearProfiles(state, action: PayloadAction) {
        state.profiles = {}
    },
    setLabel(state, action: PayloadAction<string>) {
        state.label = action.payload
    },
    setInstance(state, action: PayloadAction<string>) {
        state.instance = action.payload
    },
    setPosts(state, action: PayloadAction<IAgoraPost[]>) {
        state.posts = action.payload
    },
    setNews(state, action: PayloadAction<IAgoraPost[]>) {
        state.news = action.payload
    },
    setRelations(state, action: PayloadAction<{
        id: string,
        article_url: string,
        title: string,
        source: string
    }[]>) {
        state.relations = action.payload
    }
  }
})

export const {
    setLoading,
    setProfile,
    setLabel,
    setDescription,
    setInstance,
    setPosts,
    setExProfile,
    setHasProfile,
    clearProfiles,
    setNews,
    setRelations
} = entitiyDetails.actions

export default entitiyDetails.reducer

export const fetchPostsAndProfiles = (profiles: {
    [source: string]: string,
}): AppThunk => async (dispatch, getState) => {

    dispatch(setLoading(true))
    dispatch(setPosts([]))
    dispatch(clearProfiles())

    let new_profiles: IAgoraExProfile[] = []
    await Promise.all(Object.keys(profiles).map(async (source: string) => {
        let profile = await externalSourceControl.fetchProfile(source, profiles[source])
        new_profiles.push(profile)
        if (profile)
            dispatch(setExProfile(profile))
    }));

    let allPosts: IAgoraPost[] = []

    await Promise.all(new_profiles.map(async (profile: IAgoraExProfile) => {
        if (profile) {
            let posts: IAgoraPost[] = await externalSourceControl.fetchPosts(profile)
            allPosts = posts.concat(allPosts);
        }
    }));

    dispatch(setPosts(allPosts))
    dispatch(setLoading(false))
}

export const fetchPosts = (): AppThunk => async (dispatch, getState) => {

    dispatch(setLoading(true))

    const { profiles } = getState().profileReducer
    // const profiles: IAgoraExProfile[] = [reddit, instagram, twitter, youtube];
    let allPosts: IAgoraPost[] = []

    await Promise.all(Object.values(profiles).map(async (profile: IAgoraExProfile) => {
        if (profile) {
            let posts: IAgoraPost[] = await externalSourceControl.fetchPosts(profile)
            allPosts = posts.concat(allPosts);
        }
    }));

    dispatch(setPosts(allPosts))
    dispatch(setLoading(false))
}

export const fetchProfiles = (profiles: {
    [source: string]: string,
}): AppThunk => async (dispatch, getState) => {

    dispatch(setLoading(true))

    await Promise.all(Object.keys(profiles).map(async (source: string) => {
        let profile = await externalSourceControl.fetchProfile(source, profiles[source])
        if (profile)
            dispatch(setExProfile(profile))
    }));

    dispatch(setLoading(false))
}

export const fetchNews = (news: string): AppThunk => async (dispatch, getState) => {

    let response = await fetch(news)
    let body = await response.json()
    let newsPosts: IAgoraPost[] = body.articles.map((value: any) => {
        return {
            source: value.domain,
            date: value.seendate,
            title: value.title,
            description: "",
            link: value.url,
            media: value.socialimage,
            uid: ""
        }
    })

    dispatch(setNews(newsPosts))
}
