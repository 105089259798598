import IAgoraPost from "./IAgoraPost";
import IAgoraExProfile from "./IAgoraExProfile";
import Provider from "./Provider";
import config from './config.json'
import React from "react";
import YouTube from 'react-youtube';

// const key = config.youtube
const key = 'AIzaSyDiDMKPttp3F5igI61TpYA8VwZI2oRQgQ8'

export default class YoutubeProvider implements Provider {

    async fetchProfile(uid: string): Promise<IAgoraExProfile> {
        //https://www.googleapis.com/youtube/v3/channels?part=snippet%2CcontentDetails%2Cstatistics&id=UCXgGY0wkgOzynnHvSEVmE3A&key=AIzaSyBu1o7z4S-7LZaXJtfqQVH68R6dnnICILA

        // channelID = "UCXgGY0wkgOzynnHvSEVmE3A";
        const endpoint = `https://www.googleapis.com/youtube/v3/channels?part=snippet%2CcontentDetails%2Cstatistics&id=${uid}&key=${key}`;

        try {
            let response = await fetch(endpoint);
            let body = await response.json();

            return {
                username: body.items[0].snippet.title,
                uid: uid,
                source: "youtube",
                followers: body.items[0].statistics.subscriberCount,
                link: `https://www.youtube.com/channel/${uid}`,
                data: body.items[0].snippet.description, //String Channel Description
            };
        } catch (error) {
            console.log(error);
            return null
        }
    }


    async fetchPosts(uid: string): Promise<IAgoraPost[]> {
        //https://www.googleapis.com/youtube/v3/search?part=snippet&
        //channelId=UCXgGY0wkgOzynnHvSEVmE3A&
        //maxResults=1&
        //key=AIzaSyBu1o7z4S-7LZaXJtfqQVH68R6dnnICILA

        // channelID = "UCXgGY0wkgOzynnHvSEVmE3A";
        const numResults = "5";

        //template literal has to use ` (multiline and can include JS)
        const endpoint = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${uid}&order=date&maxResults=${numResults}&key=${key}`;

        try {
            let response = await fetch(endpoint);
            let body = await response.json();

            let posts: IAgoraPost[] = [];

            body.items.map((media: any) => {
                //TODO: reformat date
                posts.push({
                    source: "youtube",
                    title: media.snippet.title,
                    date: media.snippet.publishedAt,
                    description: media.snippet.description,
                    media: media.snippet.thumbnails.default.url,
                    link: `https://www.youtube.com/watch?v=${media.id.videoId}`,
                    uid: media.id.videoId
                });
            });

            return posts;
        } catch {
            return [];
        }
    }

    getEmbeddedPost(post: IAgoraPost): JSX.Element {
        return <YouTube videoId={post.uid} />;
    }
}
