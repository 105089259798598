import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Container, Grid, Paper, makeStyles, Divider, AppBar, Tabs, Tab, Theme, useTheme, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { getAgoraProfile } from 'api/mock'
import { useParams, useLocation } from 'react-router';
import { fetchPosts, fetchProfiles, setLabel, setDescription, setInstance, setHasProfile, fetchPostsAndProfiles, fetchNews, setRelations, setProfile } from './profileSlice';
import Skeleton from '@material-ui/lab/Skeleton';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from './components/TabPanel'
import News from '../Home/components/News'
import Discussion from './components/Discussion'
import Relations from './components/Relations'
import ProfileHeader from './components/ProfileHeader';
import { IAgoraData } from 'data/data';


// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  

export default function Profile(): JSX.Element {
    
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const dispatch = useDispatch()
    let { id } = useParams();
    const { relations, profile, posts, news, profiles } = useSelector((state: RootState) => state.profileReducer)
    // const [profile, setProfile]: [any, any] = useS
    
    useEffect(() => {
        const load = async () => {
            let profile: IAgoraData = await getAgoraProfile(id.toLowerCase())

            if (profile) {
                dispatch(setProfile(profile))
                dispatch(setLabel(profile.label))
                dispatch(setDescription(profile.description))
                dispatch(setInstance(profile.instance))
                dispatch(setRelations(profile.relations))

                const accounts: {
                    reddit: string,
                    instagram: string,
                    twitter: string,
                    youtube: string
                } = {
                    reddit: '',
                    instagram: profile.instagram,
                    twitter: profile.twitter,
                    youtube: profile.youtube
                }

                dispatch(fetchPostsAndProfiles(accounts))
                dispatch(fetchNews(profile.newsEndpoint))
            } else {
                dispatch(setHasProfile(false))
            }
        }

        load()
    }, [dispatch, id])
    
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Divider />
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12}>
                    <ProfileHeader profile={profile} profiles={profiles}/>
                </Grid>
                {/* Recent Orders */}
                <Grid item xs={12}>
                <Paper className={classes.paper}>
                <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={(event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    >
                    <Tab label="News" {...a11yProps(0)} />
                    <Tab label="Social" {...a11yProps(1)} />
                    <Tab label="Discussion" {...a11yProps(2)} />
                    <Tab label="Relationships" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={(index: number) => setValue(index)}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
            <News news={news}/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
            <News news={posts}/>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
            <Discussion />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
            <Relations relations={relations}/>
        </TabPanel>
      </SwipeableViews>
                </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}