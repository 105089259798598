import React from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Home from 'containers/Home/Home';
import Navigation from 'containers/Navigation/Navigation';
import Landing from 'containers/Landing/Landing';
import Profile from 'containers/Profile/Profile';
import Articles from 'containers/Articles/Articles';
import LoginModal from 'components/LoginModal'
import { ThemeProvider } from '@material-ui/styles';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://info.agora-data.com/">
        Agora
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});


export default function App(): JSX.Element {
  
  const classes = useStyles();
  const [loginModal, setLoginModal] = React.useState(false)

  return (

    <Router>
      <ThemeProvider theme={darkTheme}>

      <div className={classes.root}>
        <CssBaseline />
        <Navigation />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Routes>
            <Route path='/' element={<Home openLoginModal={() => setLoginModal(true)} />} />
            <Route path='/profiles' element={<Home openLoginModal={() => setLoginModal(true)} />} />
            <Route path='/articles' element={<Articles />} />
            <Route path="/entity/:id" element={<Profile />} />
          </Routes>
          <Box pt={4}>
              <Copyright />
          </Box>
        </main>
      </div>

      <LoginModal open={loginModal} handleClose={() => setLoginModal(false)}/>
      </ThemeProvider>
    </Router>
  );
}