/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { agoraData } from 'data/data'
import { useNavigate } from 'react-router';

export default function ComboBox() {

    const navigate = useNavigate()
  return (
    <Autocomplete
    options={top100Films}
    onChange={(option, value: any) => {
        if (value) navigate(`/entity/${value.id}`)}
    }
    getOptionLabel={(option) => option.label}
    renderInput={(params) => <TextField {...params} id="outlined-search" label="Search field" type="search" variant="outlined" />}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = Object.keys(agoraData).map((key: string) => {
    return {
        ...agoraData[key],
        id: key
    }
})
