import React from 'react';
import clsx from 'clsx';
import { Container, Grid, Paper, makeStyles, Divider, List, ListItem, ListItemAvatar, Avatar, ListItemText, Card, CardActionArea, CardMedia, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import IAgoraPost from 'api/external_sources/IAgoraPost';
// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    divider: {
        margin: 5
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      inline: {
        display: 'inline',
      },
      media: {
        height: 200,
      },
  }));


  interface NewsProps {
      news: IAgoraPost[]
  }
export default function News(props: NewsProps): JSX.Element {
    
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12}>
                {props.news.map((post: IAgoraPost, i: number) => <span key={i}>
                        <List className={classes.root}>
      <ListItem alignItems="flex-start" onClick={() => window.open(post.link, '_blank')} button>
       {<Card className={classes.root}>
         {
           post.media ? 
            <CardMedia
              className={classes.media}
              image={post.media}
              title="Contemplative Reptile"
            /> : null
        }
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {post.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {post.description}
          </Typography>
        </CardContent>
      <CardActions>
        <Button size="small" color="secondary">
          Share
        </Button>
      </CardActions>
    </Card>}
      </ListItem>
      </List>
                        <br />
                    </span>)}
                </Grid>
            </Grid>
        </Container>
    );
}