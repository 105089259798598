import React from 'react';
import clsx from 'clsx';
import { Container, Grid, Paper, makeStyles, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, List, ListItem, ListItemAvatar, ListItemText, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toTitleCase } from 'utils/stringUtils';
// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
      root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      inline: {
        display: 'inline',
      },
  }));

interface RelationProps {
    relations: {
        id: string,
        article_url: string,
        title: string,
        source: string
    }[]
}

export default function Relations(props: RelationProps): JSX.Element {
    
    const classes = useStyles();
    const { relations } = props

    let relationsMap: { [id: string] : {
        id: string,
        article_url: string,
        title: string,
        source: string
    }[] } = {}

    relations.forEach(value => {
        if (relationsMap[value.id]) {
            relationsMap[value.id].push(value)
        } else {
            relationsMap[value.id] = [value]
        }
    })     

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12}>
                    {Object.keys(relationsMap).map((key: string) => {
                        const r = relationsMap[key]

                        return (
                            <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography className={classes.heading}> {toTitleCase(key)} | {r.length} Connections </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <List className={classes.root}>
                            {r.map(value => (
                                <React.Fragment>
                                <ListItem alignItems="flex-start" button onClick={()=> window.open(value.article_url, '_blank')}>
                                <ListItemText
                                    primary={value.title}
                                    secondary={value.source}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            </React.Fragment>
                            ))}
                            </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        )
                    })}
                   
                </Grid>
            </Grid>
        </Container>
    );
}