import React from 'react';
import clsx from 'clsx';
import { Container, Grid, Paper, makeStyles, Typography, Card, CardContent, CardActions, Button, GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';
import { IAgoraData } from 'data/data';
import IAgoraExProfile from 'api/external_sources/IAgoraExProfile';
import { toTitleCase } from 'utils/stringUtils';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { getIcon } from 'containers/Home/components/ProfileListItem';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    root: {
        // display: 'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'space-around',
        // overflow: 'hidden',
        // backgroundColor: theme.palette.background.paper,
      },
      gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
      },
      title: {
        color: theme.palette.primary.light,
      },
      titleBar: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      },
}));

interface ProfileHeaderProps {
    profile: IAgoraData,
    profiles: {
        [source: string]: IAgoraExProfile
    }
}

export default function ProfileHeader(props: ProfileHeaderProps): JSX.Element {
    
    const classes = useStyles();
    const { profile, profiles } = props

    if (profile) {
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography gutterBottom variant="h5" component="h2"> {profile.label} </Typography>
                <GridList className={classes.gridList} cols={2.5}>
                    {Object.keys(profiles).map((key: string)=> { 
                        const p = profiles[key]
                        return(
                            <Card className={classes.root} variant="outlined">
                            <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                {toTitleCase(key)}
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {p.username}
                            </Typography>
                            <Typography variant="body1" component="h2">
                                {`Followers: ${p.followers}`}
                            </Typography>
                            </CardContent>
                            <CardActions>
                                {getIcon(p.source, p.link)}
                            </CardActions>
                        </Card>)   
            })}
        </GridList>
        </Container>
    );
            } return null
}