import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Container, Grid, Paper, makeStyles, Card, CardContent, Typography, CardActions, Button, GridList, GridListTile, ListSubheader, GridListTileBar, IconButton, List, ListItem, CardMedia, CardActionArea, CardHeader, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Divider, Tabs, Tab } from '@material-ui/core';
import { agoraData } from 'data/data'
import { Link, useNavigate } from 'react-router-dom';
import IAgoraExProfile from 'api/external_sources/IAgoraExProfile';
import News from './News'
import Social from './Social'
import TabPanel from 'containers/Profile/components/TabPanel';
import IAgoraPost from 'api/external_sources/IAgoraPost';
import { useDispatch } from 'react-redux';
import { fetchNews, fetchPostsAndProfiles } from 'containers/Profile/profileSlice';
// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
      height: 400,
    },
    highlight: {
        height: 100
    },
    inline: {
        display: 'inline',
        width: '90%'
    },
    card: {
        height: 200
    },
    action: {
        display: "flex",
        justifyContent: "space-between"
    },
  }));

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}

interface ProfileListProps {
    profile: {
        youtube: string
        instagram: string
        twitter: string,
        label: string,
        instance: string,
        description: string,
        newsEndpoint: string,
        qid: string
    },
    news: IAgoraPost[]
    posts: IAgoraPost[]
}

export default function ProfileList(props: ProfileListProps): JSX.Element {
    
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { profile, posts } = props

    useEffect(() => {
        if (profile) {
            dispatch(fetchNews(props.profile.newsEndpoint))
            dispatch(fetchPostsAndProfiles({
                'instagram': profile.instagram,
                'twitter': profile.twitter,
                'youtube': profile.youtube
            }))
        }
    }, [profile])

    
    return (
        <div>
        <Tabs
        value={value}
        onChange={(event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
        >
               <Tab label="News" {...a11yProps(0)}/>
                <Tab label="Social" {...a11yProps(1)}/>
                    
        </Tabs>
        <TabPanel value={value} index={0}>
            <News news={props.news.filter((p: IAgoraPost) => p.media !== "")}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <News news={posts}/>
        </TabPanel>
        </div>
    );
}