import React from 'react';
import clsx from 'clsx';
import { Chip, Grid, Paper, makeStyles, Card, CardContent, Typography, CardActions, Button, GridList, GridListTile, ListSubheader, GridListTileBar, IconButton, List, ListItem, CardMedia, CardActionArea, CardHeader, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Divider, Box } from '@material-ui/core';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import { fade } from '@material-ui/core/styles/colorManipulator'
import InstagramIcon from '@material-ui/icons/Instagram'
import { toTitleCase } from 'utils/stringUtils';
// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

const useStyles = makeStyles((theme) => ({
      card: {
          width: '100%'
      }
}));

  export function getIcon (source: string, link: string) : JSX.Element {
    let icon: JSX.Element = null

    switch (source) {
            case 'instagram':
                icon = <Chip
                    style={{backgroundColor:'#F56040'}}
                    onClick={() => window.open(link, '_blank') }
                    icon={<InstagramIcon />} 
                    // color="#F56040"
                    label={"Instagram"}
                />
                break
            case 'twitter':
                icon = <Chip 
                style={{backgroundColor:'#55acee'}}
                icon={<TwitterIcon />} 
                // color="#"
                label={"Twitter"}
                />
                break
            case 'youtube':
                icon = <Chip 
                style={{backgroundColor:'#cd201f'}}
                icon={<YouTubeIcon />} 
                // color=""
                label={"Youtube"}
                />
                break
        }
        return icon
    }

interface ProfileListProps {
    profile: {
            youtube: string
            instagram: string
            twitter: string,
            label: string,
            instance: string,
            description: string,
            newsEndpoint: string,
            qid: string,
            relations: {
                id: string,
                article_url: string,
                title: string,
                source: string
            }[],
            pinned_article: {
                article_url: string,
                title: string,
                source: string
            },
            trending: boolean
        }
    openLoginModal: () => void
    previewProfile: (profile: any) => void
    onView: () => void
}

export default function ProfileListItem(props: ProfileListProps): JSX.Element {
    
    const classes = useStyles();
    const { profile, openLoginModal, previewProfile, onView } = props

    return (<ListItem button onClick={() => previewProfile(profile)}>
               <Card className={classes.card}>
               <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">  R </Avatar>
                    }
                    // action={
                    // <IconButton aria-label="settings">
                    //     <MoreVertIcon />
                    // </IconButton>
                    // }
                    title={<Typography gutterBottom variant="h5" component="h3"> {profile.label} </Typography>}
                    subheader={<div> {toTitleCase(profile.instance)} <br /> {profile.description} </div>}
                />
                    <CardContent>
                        <div>
                            <Typography gutterBottom variant="h6" component="h3">
                                Pinned Article
                            </Typography>
                            <Typography gutterBottom variant="body1">
                                {profile.pinned_article.title}
                            </Typography>
                        </div>

                        <Divider />

                        <div>
                            <Typography gutterBottom variant="h6" component="h3">
                                {profile.relations.length} new relationships to other profiles.
                            </Typography>
                        </div>
                    </CardContent>
                <CardActions>
                    {
                        [
                            getIcon('youtube', `https://youtube.com/${profile.youtube}`),
                            <Divider orientation="vertical"/>,
                            getIcon('twitter', `https://twitter.com/${profile.twitter}`),
                            <Divider orientation="vertical"/>,
                            getIcon('instagram', `https://instagram.com/${profile.instagram}`),
                        ]
                    }
                    <Divider />
                    <Button size="small" color="secondary">
                        Share
                    </Button>
                    <Button size="small" color="secondary" onClick={() => openLoginModal()}>
                        {profile.trending ? 'Follow' : "Unfollow"}
                    </Button>
                    <Button size="small" color="secondary" onClick={() => onView()}>
                        View Page
                    </Button>
                </CardActions>
                </Card>
            </ListItem>)
}