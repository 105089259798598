import IAgoraExProfile from "api/external_sources/IAgoraExProfile";
import IAgoraPost from "api/external_sources/IAgoraPost";

export interface IAgoraData {
      youtube: string
      instagram: string
      twitter: string,
      label: string,
      instance: string,
      description: string,
      newsEndpoint: string,
      qid: string,
      relations: {
          id: string,
          article_url: string,
          title: string,
          source: string
      }[],
      pinned_article: {
          article_url: string,
          title: string,
          source: string
      },
      trending: boolean
}

export const agoraData: { [id: string] : IAgoraData } = {
    "elonmusk": {
      "relations": [
        {
          "id": "nasa",
          "article_url": "https://www.cbsnews.com/news/spacex-elon-musk-founder-60-minutes-interview/",
          "title": "SpaceX founder Elon Musk in 2012 :  I would have to be insane if I thought the odds were in my favor . - 60 Minutes",
          "source": "cbsnews.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.tmz.com/2020/05/30/elon-musk-five-guys-burger-after-spacex-launch-postponed/",
          "title": "Elon Musk Grabbed Five Guys Burger After SpaceX Launch Postponement",
          "source": "tmz.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.abcactionnews.com/news/space/take-ii-of-the-nations-first-astronaut-launch-since-2011-is-a-success",
          "title": "Astronauts launch into orbit from US soil for first time in nearly a decade",
          "source": "abcactionnews.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.msn.com/en-us/news/technology/in-2004-elon-musk-discussed-partnering-with-nasa-for-the-next-era-of-space-travel-this-weekend-marks-a-major-milestone/ar-BB14OJFv",
          "title": "In 2004 , Elon Musk discussed partnering with NASA for the next era of space travel . This weekend marks a major milestone",
          "source": "msn.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.tmz.com/2018/02/06/spacex-falcon-heavy-rocket-red-tesla-roadster/",
          "title": "SpaceX Launches Powerful Falcon Heavy Rocket",
          "source": "tmz.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.washingtonpost.com/technology/2020/05/30/spacex-nasa-launch-live-updates/",
          "title": "SpaceX NASA launch live updates - The Washington Post",
          "source": "washingtonpost.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.ozy.com/presidential-daily-brief/pdb-333608/blast-off-334108/",
          "title": "Blast Off ! | OZY",
          "source": "ozy.com"
        },
        {
          "id": "nasa",
          "article_url": "https://chicago.suntimes.com/2020/5/30/21275708/elon-musk-spacex-rocket-ship-take-off",
          "title": "Elon Musk SpaceX rocket ship lifts off with 2 Americans",
          "source": "chicago.suntimes.com"
        },
        {
          "id": "spacex",
          "article_url": "https://www.marketwatch.com/video/spacex-successfully-launches-nasa-astronauts-into-space/857F9E12-2F64-4B75-A32F-838DA6F236FD.html",
          "title": "SpaceX Successfully Launches NASA Astronauts into Space",
          "source": "marketwatch.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.marketwatch.com/video/spacex-successfully-launches-nasa-astronauts-into-space/857F9E12-2F64-4B75-A32F-838DA6F236FD.html",
          "title": "SpaceX Successfully Launches NASA Astronauts into Space",
          "source": "marketwatch.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.richmond.com/news/national/photos-spacex-rocket-ship-launches-with-2-americans/collection_59d78486-2ba6-562f-970a-51a06d4cb164.html",
          "title": "Photos : SpaceX rocket ship launches with 2 Americans",
          "source": "richmond.com"
        },
        {
          "id": "nasa",
          "article_url": "https://journalstar.com/news/national/photos-spacex-rocket-ship-launches-with-2-americans/collection_75a747d9-b171-521c-acd9-eb55c70dd6de.html",
          "title": "Photos : SpaceX rocket ship launches with 2 Americans",
          "source": "journalstar.com"
        }
      ],
      "qid": "Q317521",
      "label": "Elon Musk",
      "description": "South African-born American entrepreneur",
      "instance": "human",
      "instagram": "elonmusk",
      "twitter": "elonmusk",
      "youtube": null,
      "newsEndpoint": "https://api.gdeltproject.org/api/v2/doc/doc?format=json&timespan=24H&query=Elon Musk%20sourcecountry:US&mode=artlist&maxrecords=75&sort=hybridrel",
      "pinned_article": {
        "article_url": "https://www.cbsnews.com/news/spacex-elon-musk-founder-60-minutes-interview/",
        "title": "SpaceX founder Elon Musk in 2012 :  I would have to be insane if I thought the odds were in my favor . - 60 Minutes",
        "source": "cbsnews.com"
      },
      "trending": true
    },
    "spacex": {
      "relations": [
        {
          "id": "nasa",
          "article_url": "https://www.msn.com/en-us/news/technology/spacexs-historic-demo-2-crew-dragon-astronaut-launch-full-coverage/ar-BB14NLJ0",
          "title": "SpaceX historic Demo - 2 Crew Dragon astronaut launch : Full coverage",
          "source": "msn.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.msn.com/en-gb/finance/technology/how-to-watch-spacex-launch-liftoff-time-for-the-live-stream-and-weather/ar-BB14OqTK",
          "title": "How to watch SpaceX launch : liftoff time for the live stream and weather",
          "source": "msn.com"
        },
        {
          "id": "nasa",
          "article_url": "http://www.techtimes.com/articles/249995/20200529/breaking-spacexs-starship-sn4-explods-after-static-engine-fire-test-another-catastrophic-end-after-securing-faa-approval.htm",
          "title": " [ BREAKING ] SpaceX Starship SN4 Explods After Static Engine Fire Test ; Another Catastrophic End After Securing FAA Approval",
          "source": "techtimes.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.msn.com/en-gb/finance/technology/how-to-watch-spacex-launch-liftoff-time-for-the-live-stream-and-weather/ar-BB14OwBZ",
          "title": "How to watch SpaceX launch : liftoff time for the live stream and weather",
          "source": "msn.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.nj.com/tv/2020/05/spacex-launch-saturday-time-channel-live-stream-how-to-watch-online.html",
          "title": "SpaceX launch Saturday : Time , channel , live stream , how to watch online",
          "source": "nj.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.benzinga.com/analyst-ratings/analyst-color/20/05/16145290/teslas-stock-and-spacex-launches-are-traders-looking-for-a-connection",
          "title": "Tesla Motors , Inc . ( NASDAQ : TSLA ), ( SPCE ) - Tesla Stock And SpaceX Launches : Are Traders Looking For A Connection ? ",
          "source": "benzinga.com"
        },
        {
          "id": "nasa",
          "article_url": "https://townhall.com/tipsheet/katiepavlich/2020/05/30/watch-live-the-historic-spacex-and-nasa-launch-n2569550",
          "title": "LIVE HISTORY : SpaceX and NASA Make Second Attempt to Launch Americans Back Into Space",
          "source": "townhall.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.lehighvalleylive.com/entertainment/2020/05/spacex-launch-saturday-time-channel-live-stream-how-to-watch-online.html",
          "title": "SpaceX launch Saturday : Time , channel , live stream , how to watch online",
          "source": "lehighvalleylive.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.msn.com/en-us/news/technology/5-ways-to-watch-spacex-s-historic-first-rocket-launch-of-nasa-astronauts-live-online/ar-BB14Dowv",
          "title": "5 ways to watch SpaceX historic first rocket launch of NASA astronauts live online",
          "source": "msn.com"
        },
        {
          "id": "nasa",
          "article_url": "https://au.news.yahoo.com/spacex-launch-iss-schedule-though-weather-uncertain-134019974--spt.html",
          "title": "SpaceX heading for ISS on historic private crewed flight",
          "source": "au.news.yahoo.com"
        },
        {
          "id": "nasa",
          "article_url": "https://es.gizmodo.com/como-seguir-minuto-a-minuto-el-primer-lanzamiento-tri-1843688409",
          "title": "Cómo seguir minuto a minuto el lanzamiento tripulado de SpaceX",
          "source": "es.gizmodo.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.msn.com/en-us/news/technology/things-we-saw-today-spacex-launches-the-crew-dragon-into-orbit/ar-BB14OAm4",
          "title": "Things We Saw Today : SpaceX Launches the Crew Dragon into Orbit",
          "source": "msn.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.chron.com/news/article/SpaceX-procede-con-hist-rico-lanzamiento-espacial-15305395.php",
          "title": "SpaceX procede con histórico lanzamiento espacial",
          "source": "chron.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.sfgate.com/news/article/SpaceX-procede-con-hist-rico-lanzamiento-espacial-15305395.php",
          "title": "SpaceX procede con histórico lanzamiento espacial",
          "source": "sfgate.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.msn.com/en-au/news/world/if-spacexs-rocket-fails-an-emergency-escape-system-will-save-the-astronauts-onboard-nasa-calculated-a-1-in-60-chance-they-will-need-it/ar-BB14NBa9",
          "title": "If SpaceX rocket fails , an emergency escape system will save the astronauts onboard . NASA calculated a 1 - in - 60 chance they will need it . ",
          "source": "msn.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.msn.com/en-us/news/technology/spacex-and-nasa-is-broadcasting-live-radio-chatter-from-the-astronauts-on-saturdays-historic-launch-heres-how-to-listen/ar-BB14FSCQ",
          "title": "SpaceX and NASA is broadcasting live radio chatter from the astronauts on Saturday historic launch . Here how to listen . ",
          "source": "msn.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.cnn.com/2020/05/30/tech/elon-musk-spacex-2004-interview-the-vault/",
          "title": "In 2004 , Elon Musk discussed partnering with NASA for the next era of space travel . This weekend marks a major milestone",
          "source": "cnn.com"
        }
      ],
      "qid": "Q193701",
      "label": "SpaceX",
      "description": "American space transport company",
      "instance": "aerospace manufacturer",
      "instagram": "spacex",
      "twitter": "SpaceX",
      "youtube": "UCtI0Hodo5o5dUb67FeUjDeA",
      "newsEndpoint": "https://api.gdeltproject.org/api/v2/doc/doc?format=json&timespan=24H&query=SpaceX%20sourcecountry:US&mode=artlist&maxrecords=75&sort=hybridrel",
      "pinned_article": {
        "article_url": "https://www.msn.com/en-us/news/technology/spacexs-historic-demo-2-crew-dragon-astronaut-launch-full-coverage/ar-BB14NLJ0",
        "title": "SpaceX historic Demo - 2 Crew Dragon astronaut launch : Full coverage",
        "source": "msn.com"
      },
      "trending": true
    },
    "tesla": {
      "relations": [
        {
          "id": "nasa",
          "article_url": "https://www.benzinga.com/analyst-ratings/analyst-color/20/05/16145290/teslas-stock-and-spacex-launches-are-traders-looking-for-a-connection",
          "title": "Tesla Motors , Inc . ( NASDAQ : TSLA ), ( SPCE ) - Tesla Stock And SpaceX Launches : Are Traders Looking For A Connection ? ",
          "source": "benzinga.com"
        }
      ],
      "qid": "Q478214",
      "label": "Tesla",
      "description": "American automotive, energy storage and solar power company",
      "instance": "corporation",
      "instagram": "teslamotors",
      "twitter": "Tesla",
      "youtube": "UC5WjFrtBdufl6CZojX3D8dQ",
      "newsEndpoint": "https://api.gdeltproject.org/api/v2/doc/doc?format=json&timespan=24H&query=Tesla%20sourcecountry:US&mode=artlist&maxrecords=75&sort=hybridrel",
      "pinned_article": {
        "article_url": "https://cleantechnica.com/2020/05/30/tesla-master-plan-part-trois-sort-of-battery-day%EF%BB%BF/",
        "title": "CleanTechnica | Clean Tech News & Views : Solar Energy News . Wind Energy News . EV News . & More . ",
        "source": "cleantechnica.com"
      },
      "trending": false
    },
    "nasa": {
      "relations": [],
      "qid": "Q23548",
      "label": "National Aeronautics and Space Administration",
      "description": "Independent agency of the United States Federal Government",
      "instance": "space agency",
      "instagram": "nasa",
      "twitter": "NASA",
      "youtube": "UCLA_DiR1FfKNvjuUpBHmylQ",
      "newsEndpoint": "https://api.gdeltproject.org/api/v2/doc/doc?format=json&timespan=24H&query=NASA%20sourcecountry:US&mode=artlist&maxrecords=75&sort=hybridrel",
      "pinned_article": {
        "article_url": "https://www.zerohedge.com/technology/watch-live-space-x-and-nasa-prepare-historic-launch",
        "title": "NASA Astronauts Successfully Blast Off Into Space On A SpaceX Rocket",
        "source": "zerohedge.com"
      },
      "trending": false
    },
    "joerogan": {
      "relations": [
        {
          "id": "nasa",
          "article_url": "https://www.yahoo.com/entertainment/grimes-reveals-nickname-she-calls-165259185.html",
          "title": "Grimes Reveals the Nickname She Calls Her Baby After Name Change",
          "source": "yahoo.com"
        },
        {
          "id": "nasa",
          "article_url": "https://news.yahoo.com/grimes-shares-nickname-son-elon-084935193.html",
          "title": "Grimes shares nickname for son with Elon Musk X Æ A - Xii",
          "source": "news.yahoo.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.eonline.com/ca/news/1157489/grimes-reveals-the-nickname-she-calls-her-baby-after-name-change",
          "title": "Grimes Reveals the Nickname She Calls Her Baby After Name Change",
          "source": "eonline.com"
        },
        {
          "id": "nasa",
          "article_url": "https://www.eonline.com/uk/news/1157491/grimes-reveals-the-nickname-she-calls-her-baby-after-name-change",
          "title": "Grimes Reveals the Nickname She Calls Her Baby After Name Change",
          "source": "eonline.com"
        },
        {
          "id": "nasa",
          "article_url": "https://rock1017fm.iheart.com/content/2020-05-30-grimes-nickname-for-son-x-a-xii-is-much-easier-to-pronounce/",
          "title": "Grime Nickname For Son X Æ A - Xii Is Much Easier To Pronounce",
          "source": "rock1017fm.iheart.com"
        }
      ],
      "qid": "Q2718421",
      "label": "Joe Rogan",
      "description": "American martial artist, podcaster, sports commentator and comedian",
      "instance": "human",
      "instagram": "joerogan",
      "twitter": "joerogan",
      "youtube": "UCzQUP1qoWDoEbmsQxvdjxgQ",
      "newsEndpoint": "https://api.gdeltproject.org/api/v2/doc/doc?format=json&timespan=24H&query=Joe Rogan%20sourcecountry:US&mode=artlist&maxrecords=75&sort=hybridrel",
      "pinned_article": {
        "article_url": "https://www.yahoo.com/entertainment/grimes-reveals-nickname-she-calls-165259185.html",
        "title": "Grimes Reveals the Nickname She Calls Her Baby After Name Change",
        "source": "yahoo.com"
      },
      "trending": true
    }
  }