import IAgoraPost from "./IAgoraPost";
import IAgoraExProfile from "./IAgoraExProfile";
import Provider from "./Provider";
import React from "react";
// @ts-ignore
import InstagramEmbed from 'react-instagram-embed';
import config from './config.json'

const endpoint = config.api_endpoint

export default class InstagramProvider implements Provider {

    async fetchProfile(uid: string): Promise<IAgoraExProfile> {
        try {
            let response = await fetch(`${endpoint}insta?user=${uid}`)
            let body = await response.json()
            let user = JSON.parse(body.data).user
    
            return {
                username: uid,
                uid: uid,
                source: 'instagram',
                followers: user.edge_followed_by.count,
                link: `https://instagram.com/${uid}`
            }
        } catch {
            return null
        }
    }


    async fetchPosts(uid: string): Promise<IAgoraPost[]> {
        try {
            let response = await fetch(`${endpoint}insta?user=${uid}`)
            let body = await response.json()
            let medias = JSON.parse(body.data).medias
            let posts: IAgoraPost[] = []
    
            medias.map((media: any) => {
                posts.push({
                    source: 'instagram',
                    uid: media.shortcode,
                    title: media.text,
                    date: (parseInt(media.date) * 1000).toString(),
                    description: '',
                    media: media.display_url,
                    link: `https://www.instagram.com/p/${media.shortcode}`
                })
            })
    
            return posts
        } catch {
            return []
        }
    }

    getEmbeddedPost(post: IAgoraPost): JSX.Element {
        return <InstagramEmbed
            url={post.link}
            hideCaption={false}
            containerTagName='div'
            protocol=''
            injectScript
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
      />
    }
}