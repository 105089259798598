import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/App';
import Amplify from 'aws-amplify';
import config from "./aws-exports";
import store from 'app/store';
// LogRocket.init('c526yo/agora-dev');

Amplify.configure(config);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
