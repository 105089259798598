import React from 'react';
import clsx from 'clsx';
import { Chip, Grid, Paper, makeStyles, Card, CardContent, Typography, CardActions, Button, GridList, GridListTile, ListSubheader, GridListTileBar, IconButton, List, ListItem, CardMedia, CardActionArea, CardHeader, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Divider, Box } from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';
import IAgoraExProfile from 'api/external_sources/IAgoraExProfile';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram'
import ProfileListItem from './ProfileListItem';
// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
      height: 400,
    },
    highlight: {
        height: 100
    },
    inline: {
        display: 'inline',
        width: '90%'
    },
    card: {
        height: 200
    },
    action: {
        display: "flex",
        justifyContent: "space-between"
    },
    divider: {
        margin: 5
    }
  }));

  function getIcon (source: string, link: string) : JSX.Element {
    let icon: JSX.Element = null

    switch (source) {
            case 'instagram':
                icon = <Chip
                    onClick={() => window.open(link, '_blank') }
                    icon={<InstagramIcon />} 
                    // color="#F56040"
                    label={"Instagram"}
                />
                break
            case 'twitter':
                icon = <Chip 
                icon={<TwitterIcon />} 
                // color="#F56040"
                label={"Twitter"}
                />
                break
            case 'youtube':
                icon = <Chip 
                icon={<YouTubeIcon />} 
                // color="#F56040"
                label={"Youtube"}
                />
                break
        }
        return icon
    }

interface ProfileListProps {
    profiles: {
        [id: string]: {
            youtube: string
            instagram: string
            twitter: string,
            label: string,
            instance: string,
            description: string,
            newsEndpoint: string,
            qid: string,
            relations: {
                id: string,
                article_url: string,
                title: string,
                source: string
            }[],
            pinned_article: {
                article_url: string,
                title: string,
                source: string
            },
            trending: boolean
        }
    }
    openLoginModal: () => void
    previewProfile: (profile: any) => void
}

export default function ProfileList(props: ProfileListProps): JSX.Element {
    
    const classes = useStyles();

    const navigate = useNavigate();
    const { profiles, openLoginModal, previewProfile } = props

    return (
            <List className={classes.root}>
                {Object.keys(profiles).map((key: string, i: number) => {
                    let profile = profiles[key]
                    return (<ProfileListItem profile={profile} openLoginModal={openLoginModal} previewProfile={previewProfile} onView={() => navigate(`/entity/${key}`)}/>)
                })}
                </List>  
    );
}