import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import SideDrawer from './components/SideDrawer';
import NavBar from './components/NavBar';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://info.agora-data.com/">
        Agora
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Navigation(): JSX.Element {
  
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
                <NavBar open={open} handleDrawerOpen={() => setOpen(true)}/>
                <SideDrawer open={open} handleDrawerClose={() => setOpen(false)}/>
        </React.Fragment>
    );
}