import { combineReducers } from '@reduxjs/toolkit'
import userReducer from './rootSlice'
import profileReducer from 'containers/Profile/profileSlice'

const rootReducer = combineReducers({
  userReducer: userReducer,
  profileReducer: profileReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer