import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';

interface LoginModalProps {
    open: boolean
    handleClose: () => void
}

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export default function LoginModal(props: LoginModalProps) {
    const classes = useStyles();
    const {open, handleClose} = props

    return (
        <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {<div style={{
                            top: `${50}%`,
                            left: `${50}%`,
                            transform: `translate(-${50}%, -${50}%)`,
                        }} 
                        className={classes.paper}>
                <h2 id="simple-modal-title"> Login </h2>
                <p id="simple-modal-description">
                    Login here
                </p>
            </div>}
        </Modal>
        </div>
    );
}