import Provider from "./Provider";
import InstagramProvider from "./InstagramProvider";
import TwitterProvider from "./TwitterProvider";
import YoutubeProvider from "./YoutubeProvider";
import IAgoraPost from "./IAgoraPost";
import IAgoraExProfile from "./IAgoraExProfile";

export default class ExternalSourceController {

    provider_map: {[source: string]: Provider}

    constructor() {
        this.provider_map = {
            'instagram': new InstagramProvider(),
            'twitter': new TwitterProvider(),
            'youtube': new YoutubeProvider()
        }
    }

    async fetchPosts(profile: IAgoraExProfile): Promise<IAgoraPost[]> {

        let posts: IAgoraPost[] = []
        const provider = this.provider_map[profile.source]

        if (provider) {
            posts = await provider.fetchPosts(profile.uid)
        }

        return posts
    }

    async fetchProfile(source: string, uid: string): Promise<IAgoraExProfile> {
        const provider = this.provider_map[source]

        if (provider) {
            return await provider.fetchProfile(uid)
        }

        return null
    }

    fetchEmbeddedPost(post: IAgoraPost): JSX.Element {
        const provider = this.provider_map[post.source]

        if (provider) {
            return provider.getEmbeddedPost(post)
        }

        return null
    }
}