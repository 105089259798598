import React, { useState } from 'react';
import clsx from 'clsx';
import { Container, Grid, Paper, makeStyles, Card, CardContent, Typography, CardActions, Button, GridList, GridListTile, ListSubheader, GridListTileBar, IconButton, List, ListItem, CardMedia, CardActionArea, CardHeader, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Divider, Tabs, Tab } from '@material-ui/core';
import { agoraData } from 'data/data'
import { Link, useLocation } from 'react-router-dom';
import ProfileList from './components/ProfileList';
import ProfilePreview from './components/ProfilePreview';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import TabPanel from 'containers/Profile/components/TabPanel';

// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
      height: 400,
    },
    highlight: {
        height: 100
    },
    inline: {
        display: 'inline',
        width: '90%'
    },
    card: {
        height: 200
    },
    action: {
        display: "flex",
        justifyContent: "space-between"
    },
  }));

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}

interface HomeProps {
    openLoginModal: () => void
}

export default function Home(props: HomeProps): JSX.Element {
    
    const classes = useStyles();
    const fixedHighlight = clsx(classes.paper, classes.highlight);
    const { openLoginModal } = props
    const [value, setValue] = React.useState(0);


    const { news, posts } = useSelector((state: RootState) => state.profileReducer)
    const location = useLocation();
    const [profile, setProfile]: [any, any] = useState(null)

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
            {location.pathname === '/' ? 
                <Grid item xs={12}>
                    <Paper className={fixedHighlight}>
                    <Typography variant="h3" color="textPrimary" align="center"> Your Agora Dashboard </Typography>
                    </Paper>
                </Grid> : null}
            

                <Grid item xs={12} md={7}>
                    <Paper>
                                        <Tabs
                            value={value}
                            onChange={(event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue)}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            >
                                    <Tab label="Who You Follow" {...a11yProps(0)}/>
                                    <Tab label="Trending" {...a11yProps(1)}/>
                                        
                            </Tabs>

                            <TabPanel value={value} index={0}>
                                <ProfileList profiles={Object.fromEntries(Object.entries(agoraData).filter(([key, value]) => !value.trending))} openLoginModal={openLoginModal} previewProfile={(profile: any) => setProfile(profile)}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ProfileList profiles={Object.fromEntries(Object.entries(agoraData).filter(([key, value]) => value.trending))} openLoginModal={openLoginModal} previewProfile={(profile: any) => setProfile(profile)}/>
                            </TabPanel>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Paper>
                        <ProfilePreview profile={profile} news={news} posts={posts} />
                    </Paper>
                </Grid>
             </Grid>
        </Container>
    );
}